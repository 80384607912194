<template>
<v-container>
  <div>
    <navbar title="مکان ها" route='/apps'/>
    <v-main >
      <location/>
      <router-link to="/add_address">
        <addicon icon= 'mdi-plus' class="addBtn"/>
      </router-link>
    </v-main>
  </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import location from './components/cards/cardLocation'
import addicon from '@/components/buttons/iconBtn'
import { getAddress } from './models/address'
export default {
  components:{
    navbar,
    location,
    addicon
  },
  created () {
    getAddress()
  },
}
</script>